import React from "react";


const LiveAPIGuide = ({
  connectpaper1,
  connectLive6,
  connectLive14,
  connectLive15,
  connectLive13,
  connectLive7,
  connectLive8,
  connectLive9
}) => {
 

  return (
    <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
        <p className="text-xl font-semibold text-gray-800 mb-4">
          This guide will walk you through the steps to Connect to Live API.
          Follow these steps carefully, and refer to the images provided at each
          step for visual guidance.
        </p>

        <div className="flex gap-3 mb-3">
          <a
            href="https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072"
            target="_blank"
          >
            <button
              type="button"
              className="text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none transition-transform duration-300"
            >
              Binance
            </button>
          </a>

          <a href="https://www.kucoin.com/support/360015102174" target="_blank">
            <button
              type="button"
              className="text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none transition-transform duration-300"
              
            >
              Kucoin
            </button>
          </a>
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-md font-binance-plex leading-7">
            Step 1: Access the Dashboard
          </p>
          <p className="text-md font-binance-plex leading-7">
            Step 2: Click on "API Credentials"and choose the Exchanges & type of
            API from "Live Section".
          </p>
          <p className="text-md font-binance-plex leading-7">
            Step 3:  Click the "Copy" button
          </p>
          <p className="text-md font-binance-plex leading-7">
            Step 4: Go to the Exchange
          </p>

          <p className="text-md font-binance-plex leading-7">
            Step 5: After Completing Step3 and 4 Click on Next Button
          </p>

          <p className="text-md font-binance-plex leading-7">
            Step 6: Accept the Conditions and Click Connect Button
          </p>
          <p className="text-md font-binance-plex leading-7">
            Step 7: Successfully Live API Created 
          </p>

          <p className="text-md font-binance-plex leading-7 mt-3">
            To allow your bot to interact with Binance, you will need to create
            an API Key. This key acts as a connection between{" "}
            <span className="text-colorAccent">Odecent Dashboard</span> and the
            exchange, enabling your bot to perform tasks such as placing
            automated orders and accessing your balance for its calculations.
            Essentially, the API Key serves as a way for your bot to communicate
            with the exchange and execute the actions necessary for automated
            trading.
          </p>
        </div>
        <hr class="border-2 border-gray-400 my-4" />

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 1. Navigate to the{" "}
            <span className="text-colorAccent">Odecent Dashboard</span>.
          </p>
          <img src={connectpaper1} alt="" className="my-4" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 2. Click on{" "}
            <span className="text-colorAccent">"API Credentials"</span>
            and choose the Exchanges & type of API from{" "}
            <span className="text-colorAccent">"Live Section"</span>.
          </p>
          <img src={connectLive6} alt="" className="my-4" />
        </div>

        {/* // */}
        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 3. Click the "Copy" button to copy the IP's
          </p>
          <img src={connectLive8} alt="" className="my-4" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 4. Paste the copied IP addresses into the field and make sure Enable Futures and Enable Spot & Margin Trading are selected before you hit save 
          </p>
          <img src={connectLive9} alt="" className="my-4" />
        </div>




        {/* // */}

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 5. Provide the necessary information, including the API Name,
            API Key, Secret Key,Total Investment , & Click the "Next" button to proceed
          </p>
          <img src={connectLive7} alt="" className="my-4" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 6. Check the box to confirm the condition. & Click the
            "Connect" button to proceed.
          </p>
          <img src={connectLive13} alt="" className="my-4" />
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 7. Once the connection is established, a confirmation pop-up
            will appear.
          </p>
          <img src={connectLive14} alt="" className="my-4" />
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 8. After Clicking on Ok,you will be redirected to Bots .
          </p>
          <img src={connectLive15} alt="" className="my-4" />
        </div>
      </div>
    </>
  );
};

export default LiveAPIGuide;
