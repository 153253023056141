import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'



const TopupGuide = ({login1,deposit1,deposit2,deposit3,deposit4,deposit5,deposit6,deposit7}) => {
  return (
   <>
     <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to deposit funds into
            your Odecent wallet using CoinPayment. Follow these steps carefully,
            and refer to the images provided at each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 ">
              1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Begin by navigating to the Odecent login page. Enter your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                username
              </span>
              &{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                password{" "}
              </span>{" "}
              in the designated fields and click on the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Login{" "}
              </span>
              button to access your account.
            </p>
            <img
              src={login1}
              alt="Login Page"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">2:</span> Navigate to the Top-Up Section
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Once logged in, locate the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Top-Up{" "}
              </span>{" "}
              button on the top-right corner of the dashboard. Click this button
              to initiate the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                deposit process
              </span>
              .
            </p>
            <img
              src={deposit1}
              alt="Top-Up Section"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">3:</span> Enter the Deposit Amount
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              You can now specify the amount you wish to deposit. Enter the
              desired amount manually or select one of the prefixed amounts
              displayed on the page. Once done, click the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Next
              </span>{" "}
              button to proceed.
            </p>
            <img
              src={deposit2}
              alt="Deposit Amount"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">4:</span> Choose Your Preferred
              Cryptocurrency
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              You will be presented with a list of{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                supported cryptocurrencies
              </span>
              . Select the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                coin
              </span>{" "}
              you want to use for your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                deposit
              </span>
              .<br /> Ensure you review the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                coin’s exchange rate
              </span>
              , which will be shown on the page.
            </p>
            <img
              src={deposit3}
              alt="Cryptocurrency Selection"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">5:</span> Review the Conversion and Proceed
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              After selecting your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                cryptocurrency
              </span>
              , check the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                conversion rate
              </span>{" "}
              for the amount you wish to deposit. The conversion details,
              including any{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                applicable fees
              </span>
              , will be displayed. Once confirmed, click the
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                CoinPayment
              </span>{" "}
              button to proceed to the payment page.
            </p>
            <img
              src={deposit4}
              alt="Conversion Review"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">6:</span> Complete the Payment on
              CoinPayment
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              You will be redirected to the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                CoinPayment page{" "}
              </span>
              , where you will see the exact{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                amount{" "}
              </span>{" "}
              of the selected{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                cryptocurrency{" "}
              </span>{" "}
              you need to transfer. <br />A{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                barcode{" "}
              </span>
              will be provided for easy payment.
              <br /> Be aware to add some additional funds may be required to{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                cover gas fees
              </span>
              .<br /> If you don't add an additional fund for gas fees, the
              amount credited to your
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                Odecent wallet{" "}
              </span>{" "}
              will be less after fees are deducted.
            </p>
            <img
              src={deposit5}
              alt="CoinPayment Page"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">7:</span> Wait for Payment Confirmation
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              After completing the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                payment
              </span>
              , you will need to wait for CoinPayment to confirm the
              transaction. You will receive email updates about your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                payment status
              </span>
              .
            </p>
            <img
              src={deposit6}
              alt="Payment Confirmation"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">8:</span> Check Payment Status
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Once payment is{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                confirmed{" "}
              </span>
              , you can monitor the status by navigating to your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                dashboard
              </span>
              .<br />{" "}
              <p className="text-md font-binance-plex leading-7">
                Once logged in, go to{" "}
                <span className="text-SecondaryColor text-lg font-bold uppercase">
                  User Dashboard ➔ Transaction ➔ Deposit Transaction Table
                </span>
              </p>
              .In the last column labeled{" "}
              <span className="text-SecondaryColor font-bold">Status</span> you
              will see the current status of your <br />{" "}
              <span className="text-SecondaryColor font-bold">
                Transaction:
              </span>{" "}
              <span className="text-yellow-600 font-bold">Pending</span>,{" "}
              <span className="text-red-600 font-bold">Failed</span>, or{" "}
              <span className="text-green-600 font-bold">Successful</span>
              <br />
              Additionally, a link to the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                CoinPayment{" "}
              </span>{" "}
              status page will be provided.
            </p>
            <img
              src={deposit7}
              alt="Payment Status"
              className="my-4 rounded-lg shadow-lg"
            />
          </div>
        </div>
   
   </>
  )
}

export default TopupGuide