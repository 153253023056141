import React from "react";

const TradesGuide = ({
  trades1,
  trades2,
  trades3,
  trades4,
  trades5,
  trades6,
}) => {
  return (
    <>
      {/* // paper trade open trades  */}
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
        <p className="text-xl font-semibold text-gray-800 mb-4">
          This guide will walk you through the steps to Trades. Follow these
          steps carefully, and refer to the images provided at each step for
          visual guidance.
        </p>

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-md font-binance-plex leading-7">
            Step 1: Access the{" "}
            <span className="text-SecondaryColor font-bold"> Dashboard </span>{" "}
            and Find the{" "}
            <span className="text-SecondaryColor font-bold">Trades </span>{" "}
            Section
          </p>
          <p className="text-md font-binance-plex leading-7">
            Step 2: Access the{" "}
            <span className="text-SecondaryColor font-bold"> Paper </span> Type
          </p>

          <p className="text-md font-binance-plex leading-7">
            Step 3: Click on the{" "}
            <span className="text-SecondaryColor font-bold">Open Trades </span>
          </p>
          <p className="text-md font-binance-plex leading-7">
            Step 4: Details of Your{" "}
            <span className="text-SecondaryColor font-bold"> Open Trades </span>
          </p>

          <p className="text-md font-binance-plex leading-7">
            Step 5: Click on{" "}
            <span className="text-SecondaryColor font-bold">
              {" "}
              Trade History{" "}
            </span>
          </p>

          <p className="text-md font-binance-plex leading-7">
            Step 6: Details of Your{" "}
            <span className="text-SecondaryColor font-bold">
              {" "}
              Trade History{" "}
            </span>
          </p>
        </div>
        <hr class="border-2 border-gray-400 my-4" />
        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
            Step 1. Go to the{" "}
            <span className="text-colorAccent">Odecent Dashboard</span> & look
            for the "Trades" section.
          </p>
          <img src={trades1} alt="" className="my-4" />
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 2. Access the Paper Type in Trades
          </p>
          <img src={trades2} alt="" className="my-4" />
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 3. Access the Open Trades of Paper
          </p>
          <img src={trades3} alt="" className="my-4" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-md font-binance-plex leading-7">
    <span className="text-SecondaryColor font-bold text-lg">
      Step 4. This will lead you to view your Open Trades details, which
      include the following information:
    </span>
    <ul className="list-disc ml-14 mt-2">
      <li>
        <span className="font-semibold text-SecondaryColor ">Risk:</span> A measure of the potential losses or gains in a trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Symbol:</span> A unique identifier representing the trading pair, e.g., BTC/USDT.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Pair:</span> The trading pair used in the trade, indicating the base and quote assets.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Bot Name:</span> The name of the automated trading bot managing this trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">API Name:</span> The API name used for executing the trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Side:</span> Indicates whether the position is a "LONG" or "SHORT."
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Avg Price ($):</span> The average price at which the asset was bought or sold.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Quantity:</span> The number of units or assets involved in the trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Size:</span> The total value of the trade, calculated as Quantity × Avg Price.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Unrealized Pnl (%):</span> The percentage profit or loss of the trade based on current market prices.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Unrealized Pnl ($):</span> The dollar value of the profit or loss of the trade.
      </li>
    </ul>
  </p>
  <img src={trades4} alt="Open Trades Diagram" className="my-4" />
</div>


        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
            Step 5. Click on Trade History
          </p>
          <img src={trades5} alt="" className="my-4" />
        </div>

        <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-md font-binance-plex leading-7">
    <span className="text-SecondaryColor font-bold text-lg">
      Step 4. This will lead you to view your Trades History details,
      which include the following information:
    </span>
    <ul className="list-disc ml-14 mt-2">
      <li>
        <span className="font-semibold text-SecondaryColor">DateTime:</span> The date and time when the trade or transaction was executed.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Exchange:</span> The platform or marketplace where the trade was executed.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">API Name:</span> The API used for executing the trade and fetching market data.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Exchange Type:</span> Indicates the type of exchange, such as spot or future.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Bot Name:</span> The name of the trading bot responsible for the trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Symbol:</span> The trading pair symbol, e.g., BTC/USDT.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">OrderID:</span> The unique identifier for the specific trade order.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">BotID:</span> A unique identifier for the bot that executed the trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Side:</span> Indicates whether the trade was a "buy" or "sell."
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Price:</span> The price at which the trade was executed.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Position Side:</span> Indicates the position taken, such as "long" or "short."
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Quantity:</span> The number of units of the asset involved in the trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Trade Value:</span> The total value of the trade, calculated as Quantity × Price.
      </li>
    </ul>
  </p>
  <img src={trades6} alt="Trade History Diagram" className="my-4" />
</div>

      </div>
    </>
  );
};

export default TradesGuide;
