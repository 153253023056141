export const cardData = [
    {
        title: '1. Deposit',
        description: 'This fund allows users to deposit money that can be used to subscribe to various packages. Users can choose from a range of subscription options based on the funds available in their account.',
        jumpTo: 'section1'
    },
    {
        title: '2. Balance',
        description: 'This feature provides users with a comprehensive view of all available funds across all active APIs, giving them a complete picture of their financial resources at their disposal',
        jumpTo: 'section2'
    },
    {
        title: '3. Bot Alloted',
        description: 'Funds allocated for automated trading by the bot within ABC are sourced from all connected APIs, ensuring that the bot has access to resources from multiple sources for efficient trading operations.',
        jumpTo: 'section3'
    },
    {
        title: '4. Total Bot Profit %',
        description: 'Total Bot Profit Percentage (Achieved): This metric represents the overall percentage of profit successfully generated by the trading bot.',
        jumpTo: 'section4'
    },
    {
        title: "5. Active API",
        description: "This term refers to the currently active Application Programming Interfaces (APIs) that are connected and integrated with the system, enabling seamless data exchange and functionality within the platform",
        jumpTo: 'section5'
    },
    {
        title: "6. Avg. Profit (%)",
        description: 'This metric represents the average percentage of profit generated by all currently active bots within the system. It provides an overview of the collective performance of automated processes and trading algorithms',
        jumpTo: 'section6'
    },
    {
        title: "7. Open Position",
        description: 'This term refers to the current, unsettled trading positions held by all active bots within the system. It provides an overview of the ongoing trades and investments made by automated processes and trading algorithms.',
        jumpTo: 'section7'
    },
    {
        title: "8. Closed Trades",
        description: 'This refers to the cumulative number of trades executed and subsequently closed by all currently active bots within the system. It provides insight into the overall trading activity and performance of automated processes',
        jumpTo: 'section8'
    },
    {
        title: "9. Active Bots",
        description: 'This term signifies the real-time data and status information of all the currently active bots within the Finginius system. It provides an overview of the operational state and performance of automated processes and trading algorithms',
        jumpTo: 'section9'
    },
    {
        title: "10. Avg.Profit ($)",
        description: 'This metric represents the mean financial gain or profit generated by all currently active bots within finginus',
        jumpTo: 'section10'
    },
    {
        title: "11. Open Position ($)",
        description: 'This represents the current monetary value of open positions held by all active bots within the system. It provides an overview of the financial exposure resulting from ongoing trading activities.',
        jumpTo: 'section11'
    },
    {
        title: "12. Total Profit ($) ",
        description: 'To calculate the total profit ($) from all the active bots in ABC, you would need to access the profit data for each individual bot and then sum them up. This would typically involve querying the profit records for each active bot and adding up the values. The specific process and commands for doing this would depend on the software or system you are using for managing your bots and their profits.',
        jumpTo: 'section12'
    },
    {
        title: "13. Api Name",
        description: "Unlock personalized and targeted digital interactions with UniqueID Connector—an API that sets your integration apart, offering distinct identification for seamless online experiences.",
        jumpTo: 'section13'
    },
    {
        title: "14. Api Key",
        description: "An exchange API key is a confidential code enabling software applications to interact with your exchange account securely, allowing third-party services access without sharing login credentials.",
        jumpTo: 'section14'
    },
    {
        title: "15. Secret Key",
        description: "The Secret Key in API key creation for cryptocurrency exchanges is a confidential code crucial for secure communication. Paired with the API key, it acts as a digital lock, safeguarding your account. Keep it private, update keys regularly, and exercise caution in granting permissions for enhanced security during exchange integration.",
        jumpTo: 'section15'
    },
    {
        title: "16. IPs to whitelist",
        description: "Implementing IP access restrictions, particularly whitelisting trusted IPs, is paramount for exchange security. By doing so, you fortify your account against unauthorized access, adding an additional layer of protection. This precautionary measure ensures that only pre-approved, secure IPs have the privilege of interacting with your exchange account, minimizing potential risks.",
        jumpTo: 'section16'
    },
    {
        title: "17. Api Name",
        description: "Begin by giving your Paper API a distinctive name. This ensures easy recognition and management of your virtual trading account.",
        jumpTo: 'section17'
    },
    {
        title: "18. Amount in (ETH)",
        description: "In the Paper API, you can allot virtual funds in ETH. This allocation represents the virtual capital you'll use for paper trading. Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
        jumpTo: 'section18'
    },
    {
        title: "19. Amount in (BTC)",
        description: "In the Paper API, you can allot virtual funds in BTC. This allocation represents the virtual capital you'll use for paper trading. Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
        jumpTo: 'section19'
    },
    {
        title: "20. Amount in (USDT)",
        description: "In the Paper API, you can allot virtual funds in USDT. This allocation represents the virtual capital you'll use for paper trading. Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
        jumpTo: 'section20'
    },
    {
        title: "21. Amount in (BNB)",
        description: "In the Paper API, you can allot virtual funds in BNB. This allocation represents the virtual capital you'll use for paper trading. Set your virtual balance carefully, considering the assets you wish to practice with and your desired strategy.",
        jumpTo: 'section21'
    },
];
