import React, { useEffect } from 'react'
import comp from "../Image/CoinLogo/1.webp"
import comp1 from "../Image/CoinLogo/2.webp"
import comp2 from "../Image/CoinLogo/3.webp"
import comp3 from "../Image/CoinLogo/4.webp"
import comp4 from "../Image/CoinLogo/5.webp"
import comp5 from "../Image/CoinLogo/6.webp"
import comp6 from "../Image/CoinLogo/7.webp"
import Aos from 'aos'
import bg from "../Image/Website_images/Background.webp"

function RunBots() {

    const images = [
        { src: comp, alt: 'Binance' },
        { src: comp1, alt: 'Bybit' },
        { src: comp2, alt: 'Some Exchange' },
        { src: comp3, alt: 'Binance' },
        { src: comp4, alt: 'Bybit' },
        { src: comp5, alt: 'Bybit' },
        { src: comp6, alt: 'Bybit' },
    ]

    // useEffect(() => {
    //     Aos.init({ duration: 2000, once: true }); // 'once' ensures animation occurs only once
    // }, []);
    return (
        // <div className='container mx-auto my-5 md:my-20'>
        //     <div className='grid grid-cols-3 gap-5 items-center'>
        //         <div data-aos="fade-right" className='col-span-3 md:col-span-1 '>
        //             <h1 className='text-xl sm:text-2xl lg:text-4xl font-bold py-5 text-black'><span className='text-primaryColor underline'>Run Your Bots</span> on High-Volume Cryptocurrency
        //                 Exchanges</h1>
        //             <p className='text-sm lg:text-md text-gray-500'>
        //                 Connect your exchange account with API keys and create your
        //                 crypto bot instantly.
        //             </p>
        //             <button className='bg-SecondaryColor text-white px-8 py-2 rounded-md font-bold mt-4'><a href="https://app.odecent.com">Join Now</a></button>
        //         </div>
        //         <div className='col-span-3 md:col-span-2 '>
        //             <div className=''>
        //                 <div className='flex motion-safe:animate-pulse flex-wrap justify-center gap-4 md:gap-10 xl:mx-5'>
        //                     {images.map((image, index) => (
        //                         <>
        //                             {/* <div key={index} className='flex justify-center items-center border border-gradient from-black to-white bg-white p-5 rounded-lg shadow-sm custom-border'> */}
        //                             <img key={index} src={image.src} alt={image.alt} className='drop-shadow-lg w-16 xl:w-auto shadow-lg shadow-gray-400 rounded-2xl' />
        //                             {/* </div> */}
        //                         </>
        //                     ))}
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div className='relative overflow-hidden'>
        <div className='hidden xl:block absolute inset-0'>
            <img src={bg} alt="Background" className='object-cover w-full h-full' />
        </div>
        <div className='relative container mx-auto my-5 md:my-20'>
            <div className='grid grid-cols-3 gap-5 items-center'>
                <div  className='col-span-3 md:col-span-1'>
                    <h1 className='text-xl sm:text-2xl lg:text-4xl font-bold py-5 text-black'>
                        <span className='text-SecondaryColor underline'>Run Your Bots</span> on High-Volume Cryptocurrency Exchanges
                    </h1>
                    <p className='text-sm lg:text-md text-gray-500'>
                        Connect your exchange account with API keys and create your crypto bot instantly.
                    </p>
                    <button className='bg-gradient-to-r from-SecondaryColor to-gradientColor text-white px-8 py-2 rounded-md font-bold mt-4'>
    <a href="https://app.odecent.com">Join Now</a>
</button>

                </div>
                <div className='col-span-3 md:col-span-2'>
                    <div className='flex motion-safe:animate-pulse flex-wrap justify-center gap-4 md:gap-10 xl:mx-5'>
                        {images.map((image, index) => (
                            <img key={index} src={image.src} alt={image.alt} className='drop-shadow-lg w-16 xl:w-auto shadow-lg shadow-[#2a80aa] rounded-2xl' />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RunBots