import React, { useState, useEffect } from "react";
import { Drawer, Button, Modal } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import Carousel from "../Component/Carousel";
import logo from "../Image/Navbar/logo.png";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import GoogleTranslate from "../Component/GoogleTranslate";

const Navbar = () => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [navBackground, setNavBackground] = useState("bg-transparent");
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => {
    setModalVisible(true);
    setDrawerVisible(false);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };


  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const handleScroll = () => {
    const carouselHeight = document.getElementById("carousel").offsetHeight;
    if (window.scrollY >= carouselHeight) {
      setNavBackground("bg-gradient-to-t from-SecondaryColor to-SecondaryColor");
    } else {
      setNavBackground("bg-transparent");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const smoothScrollTo = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    // setNavbarOpen(false); // Close the navbar after clicking a link
  };

  return (
    <>
      <div id="carousel">
        <Carousel />
       
      </div>
      <nav
        className={`fixed top-0 left-0 w-full border-b border-white text-white py-4 z-30 shadow-lg ${navBackground}`}
      >
        <div className="container mx-auto flex justify-between items-center px-4">
          <div className="text-2xl font-bold">
            <img src={logo} alt="odecent" />
          </div>

           <div className="">
              <GoogleTranslate />
              </div>

          <div className="hidden lg:flex space-x-6">
            <ul className="flex space-x-10 uppercase items-center justify-center">
            
           
              <li className="relative group">
                <ScrollLink
                  to="Home" // Replace 'Home' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("Home")}
                  className="relative text-white hover:text-gray-300 transition-colors cursor-pointer"
                >
                  Home
                  <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div>
                </ScrollLink>
              </li>
              <li className="relative group">
                <ScrollLink
                  to="Advantages" // Replace 'Home' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("Advantages")}
                  href="#about"
                  className="relative text-white hover:text-gray-300 transition-colors cursor-pointer"
                >
                  Advantages
                  <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div>
                </ScrollLink>
              </li>
              <li className="relative group">
                <ScrollLink
                  to="Why" // Replace 'Home' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("Why")}
                  className="relative text-white hover:text-gray-300 transition-colors cursor-pointer"
                >
                  Why?
                  <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div>
                </ScrollLink>
              </li>
              <li className="relative group">
                <ScrollLink
                  to="Work" // Replace 'Home' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("Work")}
                  className="relative text-white hover:text-gray-300 transition-colors cursor-pointer"
                >
                  Work
                  <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div>
                </ScrollLink>
              </li>
              <li className="relative group">
                <ScrollLink
                  to="Revolution" // Replace 'Home' with the ID of your component
                  smooth={true}
                  duration={500}
                  onClick={() => smoothScrollTo("Revolution")}
                  className="relative text-white hover:text-gray-300 transition-colors cursor-pointer"
                >
                  Revolution
                  <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div>
                </ScrollLink>
              </li>

              <li className="relative group  ">
                <a
                  href="/guide"
                  className="relative bg-white py-1 rounded-lg px-4 border border-SecondaryColor text-SecondaryColor hover:text-white hover:bg-transparent transition-colors"
                >
                  Guide
                  <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div>
                </a>
              </li>


              <li className="relative group ">
                <a
                  href="https://app.odecent.com"
                  className="relative bg-white py-1 rounded-lg px-4 border border-SecondaryColor text-SecondaryColor hover:text-white hover:bg-transparent transition-colors"
                >
                  Log In
                  <div className="absolute left-0 bottom-7 w-full h-0.5 bg-white scale-x-0 group-hover:scale-x-100 group-active:scale-x-100 transition-transform"></div>
                </a>
              </li>
           
            </ul>

          
          </div>
          

          <div className="lg:hidden flex items-center">
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={showDrawer}
              className="text-white"
            />
          </div>
        </div>
      </nav>

      <Drawer
        title="Menu"
        placement="left"
        onClose={onClose}
        visible={drawerVisible}
        width={250}
      >
        <ul className="space-y-4">
          <li>
            <ScrollLink
              to="Home" // Replace 'Home' with the ID of your component
              smooth={true}
              duration={500}
              onClick={() => smoothScrollTo("Home")}
              className="text-black"
            >
              Home
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="Advantages" // Replace 'Home' with the ID of your component
              smooth={true}
              duration={500}
              onClick={() => smoothScrollTo("Advantages")}
              className="text-black"
            >
              Advantages
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="Why" // Replace 'Home' with the ID of your component
              smooth={true}
              duration={500}
              onClick={() => smoothScrollTo("Why")}
              className="text-black"
            >
              Why?
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="Work" // Replace 'Home' with the ID of your component
              smooth={true}
              duration={500}
              onClick={() => smoothScrollTo("Work")}
              className="text-black"
            >
              Work
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="Revolution" // Replace 'Home' with the ID of your component
              smooth={true}
              duration={500}
              onClick={() => smoothScrollTo("Revolution")}
              className="text-black"
            >
              Revolution
            </ScrollLink>
          </li>

          {/* <li>
            <div className="relative group">
              <GoogleTranslate />
            </div>
          </li> */}
             <li>
            <a href="/guide" className="text-black">
             Guide
            </a>
          </li>

          <li>
            <a href="https://app.odecent.com" className="text-black">
              Log In
            </a>
          </li>

          {/* <li>
            <GoogleTranslate />
          </li>

           <Button type="primary" onClick={showModal}>
                                Translate
                            </Button>  */}
        </ul>
      </Drawer>

      <Modal
                title="Odecent Translate"
                visible={modalVisible}
                onCancel={handleCancel}
                footer={null} 
            > 
               {/* <GoogleTranslate/> */}
             </Modal> 
    </>
  );
};

export default Navbar;
