import React from "react";

const SignUpGuide = ({ Signupvid, singup1, singup0, singup2, singup4, login3 }) => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
      <p className="text-xl font-semibold text-gray-800 mb-4">
        This guide will walk you through the steps to SignUp. Follow these steps carefully, and refer to the images provided at each step for visual guidance.
      </p>
      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="text-md font-binance-plex leading-7">Step 1: Access the Sign-Up Page</p>
        <p className="text-md font-binance-plex leading-7">Step 2: Fill in Basic Information</p>
        <p className="text-md font-binance-plex leading-7">Step 3: Click Sign-Up</p>
        <p className="text-md font-binance-plex leading-7">Step 4: Verify Your Email</p>
        <p className="text-md font-binance-plex leading-7">Step 5: Access Your New Account</p>
      </div>

      {/* <div className="my-4 flex justify-center">
        <video className="border-2 border-SecondaryColor" controls width="640" height="360">
          <source src={Signupvid} type="video/mp4" />
        </video>
      </div> */}

      <hr className="border-2 border-gray-400 my-4" />
      <p className=" text-lg leading-6 mt-2 font-bold text-SecondaryColor">
            1. Access the Sign-Up Page from the referral Link.
          </p>

          <img src={singup1} alt="" className="my-4 w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
            2. Fill in Basic Information
          </p>

          <p className="text-md font-binance-plex leading-7">
            Choose a unique username, enter your email, name,country, password,
            and confirm your password. Accept Terms & conditions & Accept
            Captcha.
          </p>

          <img src={singup0} alt="" className="my-4 w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
            3. Click Sign Up:{" "}
          </p>

          <p className="text-md font-binance-plex leading-7">
            Click the <span className="text-colorAccent ">"Sign Up"</span>{" "}
            button to start the registration process.
          </p>

          <img src={singup2} alt="" className="my-4 w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
            4. Verify Your Email{" "}
          </p>

          <p className="text-md font-binance-plex leading-7">
            Check your email for a verification link and input the OTP (one-time
            password) to gain access to the platform.
          </p>

          <img src={singup4} alt="" className="my-4  w-[300px] h-[400px]" />
          <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
            5. Access Your New Account
          </p>

          <p className="text-md font-binance-plex leading-7">
            After email verification, you can log in with your chosen username &
            password to access your new account.
          </p>

          <img src={login3} alt="" className="my-4" />
    </div>
  );
};

export default SignUpGuide;
