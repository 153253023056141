import React, { useState } from "react";
import logo from "../Image/assets/logo.png"; // Import local assets
import signupIcon from "../Image/assets/signup.png";
import webIcon from "../Image/assets/world-wide-web.png";
import guideIcon from "../Image/assets/guide.png";
import instagramIcon from "../Image/assets/instagram.png";
import telegramIcon from "../Image/assets/telegram.png";
import whatsappIcon from "../Image/assets/whatsapp.png";
import facebookIcon from "../Image/assets/facebook.png";
import presentationIcon from "../Image/assets/Presentation .png";
import ukFlag from "../Image/assets/uk-flag.png";
import franceFlag from "../Image/assets/france-flag.png";
import spainFlag from "../Image/assets/spain-flag.png";
import arrowIcon from "../Image/assets/arrow.png";
import bgImage from "../Image/assets/bg.png"; // Import the background image
import { FaArrowAltCircleDown } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { Collapse } from "antd";
import { IoIosShareAlt } from "react-icons/io";

const SocialLink = ({ imgSrc, text, url }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="flex flex-col items-center text-center"
  >
    <img src={imgSrc} alt={text} className="w-12 h-12" />
    <p className="text-sm mt-1 text-black">{text}</p>
  </a>
);

const pptlinks = [
  {
    key: "English",
    value: "https://odecent.com/Odecent_Presentation_En.pdf",
    imgSrc: ukFlag,
    text: "Odecent Presentation English",
  },
  {
    key: "Spanish",
    value: "https://odecent.com/Odecent_Presentation_Sp.pdf",
    imgSrc: spainFlag,
    text: "Odecent Presentation Spanish",
  },
  {
    key: "French",
    value: "https://odecent.com/Odecent_Presentation_Fr.pdf",
    imgSrc: franceFlag,
    text: "Odecent Presentation French",
  },
];

const DropdownItem = () => (
  <>
    {pptlinks.map((link, index) => (
      <a
        key={index}
        href={link.value}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-between px-4 py-2 hover:bg-gray-200"
      >
        <img src={link.imgSrc} alt={link.text} className="w-5" />
        <p className="text-sm font-medium text-white">{link.text}</p>
        <IoIosShareAlt />
      </a>
    ))}
  </>
);

const Social = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

  const { Panel } = Collapse;

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
  };

  return (
    <div
      style={{ backgroundImage: `url(${bgImage})` }}
      className="bg-cover bg-center bg-no-repeat fixed top-0 left-0 w-full h-auto"
    >
      <div
        className="flex justify-center items-center min-h-screen  overflow-y-scroll overflow-x-hidden"
        // Set background image
      >
        <div className="text-center border-2 border-gray-900  w-full mx-6 md:mx-0 h-auto md:w-[450px] rounded-2xl p-6 bg-white shadow-lg">
          <img src={logo} alt="Logo" className="mx-auto mb-4 w-72" />

          {/* <div className="flex justify-center mb-4">
            <button
              className="baground-button text-white rounded-lg py-2 px-4 flex justify-center items-center gap-2 w-80 font-bold"
              onClick={() => window.open("https://odecent.com/", "_blank")}
            >
              <img src={signupIcon} alt="Signup" className="w-6 h-6" />
              SignUp
            </button>
          </div> */}

          <div className="flex justify-center mb-4">
            <button
              className="baground-button text-white rounded-lg py-2 px-4 flex justify-center items-center gap-2 w-80 font-bold"
              onClick={() => window.open("https://odecent.com/", "_blank")}
            >
              <img src={webIcon} alt="odecent" className="w-6 h-6" />
              Website
            </button>
          </div>

          <div className="flex justify-center mb-4">
            <button
              className="baground-button text-white rounded-lg py-2 px-4 flex justify-center items-center gap-2 w-80 font-bold"
              onClick={() => window.open("https://odecent.com/guide", "_blank")}
            >
              <img src={guideIcon} alt="Guide" className="w-6 h-6" />
              Guide
            </button>
          </div>

          <div className="flex justify-center">
            <div className="flex justify-center rounded-lg overflow-hidden mb-4 w-full md:w-80">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                  <IoIosArrowDown
                    className={`transform ${
                      isActive ? "rotate-180" : "rotate-0"
                    }`}
                  />
                )}
                className="w-full"
              >
                <Panel
                  header={
                    <div className="flex items-center justify-center rounded-lg gap-2">
                      <img
                        src={presentationIcon}
                        alt="Presentation"
                        className="w-6 h-6"
                      />
                      <span className="text-white font-semibold">
                        Presentation
                      </span>
                    </div>
                  }
                  key="1"
                  className="baground-button rounded-lg"
                >
                  <DropdownItem />
                </Panel>
              </Collapse>
            </div>
          </div>

          <div className="flex items-center justify-evenly mt-5 ">
            <SocialLink
              imgSrc={instagramIcon}
              url="https://www.instagram.com/odecentofficial/"
            />
            <SocialLink
              imgSrc={telegramIcon}
              url="https://t.me/odecentofficial"
            />
            <SocialLink
              imgSrc={whatsappIcon}
              url="https://whatsapp.com/channel/0029VaoYZV29sBI6Ymj87h17"
            />
            <SocialLink
              imgSrc={facebookIcon}
              url="https://www.facebook.com/odecent"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
