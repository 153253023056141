import React from 'react';
import bg_image from '../Image/Website_images/BG2.webp';
import image1 from '../Image/Website_images/EFFICIENCY.webp';
import image2 from '../Image/Website_images/ENHANCED.webp';
import image3 from '../Image/Website_images/ACCESS.webp';
import Aos from 'aos';

function Advantage() {

    // useEffect(() => {
    //     Aos.init({ duration: 2000 });
    // }, []);

    return (
        <div id="Advantages" className='m-[20px]'>
        <div  className="relative text-white  py-10 container  mx-auto m-2 rounded-2xl" style={{ backgroundImage: `url(${bg_image})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className=" text-center">
                <h1 className="text-xl lg:text-3xl font-bold ">UNLOCK A WORLD OF ADVANTAGE</h1>
                <p className='mb-6 text-sm lg:text-base'>Boost you trading edge with powerful algorithmic bots , take control of your Fund</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div  className="flex flex-col items-center">
                        <img src={image1} alt="Efficiency" className="h-20 mb-4" />
                        <h2 className="my-2 text-xl font-bold border-t border-gray-300">INCREASED EFFICIENCY</h2>
                        <p className='text-sm  md:text-base'>Choose smarter ways for faster and <br/>safer trading decisions.</p>
                    </div>
                    <div  className="flex flex-col items-center">
                        <img src={image2} alt="Discipline" className=" h-20 mb-4" />
                        <h2 className="my-2 text-xl font-bold border-t border-gray-300">ENHANCED DISCIPLINE</h2>
                        <p className='text-sm  md:text-base'>Enhance trading discipline to boost <br/>your trading skills.</p>
                    </div>
                    <div  className="flex flex-col items-center">
                        <img src={image3} alt="Access" className="h-20 mb-4" />
                        <h2 className="my-2 text-xl font-bold border-t border-gray-300">24/7 MARKET ACCESS</h2>
                        <p className='text-sm  md:text-base'>Never miss a trading opportunity <br/>with 24/7 market access.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default Advantage;
