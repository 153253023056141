import React from 'react'

const CopyBot = ({b1,b2,bot3,bot4,bot5,bot6,bot7}) => {
  return (
   <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Copy a Bot. Follow
            these steps carefully, and refer to the images provided at each step
            for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the Dashboard
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Then Access the Bots Section under Bots(Beta)
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Click the Copy Button 
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 4: Select the API
            </p>
           
            <p className="text-md font-binance-plex leading-7">
              Step 5: Click Next button and you will get the Terms & Conditions
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Accept the Terms & Conditions
            </p>

          
          </div>
          <hr class="border-2 border-gray-400 my-4" />

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1: Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span>.
            </p>
            <img src={b1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2: Go to the Bots Section under Bots(Beta).
            </p>
            <img src={b2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 3: Click on Copy Button
            </p>
            <img src={bot3} alt="" className="my-4" />
          </div>


          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 4: Set up which API you want to use.
            </p>
            <img src={bot4} alt="" className="my-4" />
          </div>


        

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 5: After Selecting the API click on Next Button.
            </p>
            <img src={bot5} alt="" className="my-4" />
          </div>

          

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 6: Check the Terms & Conditions and Click Submit
            </p>
            <img src={bot6} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
          <p className="text-SecondaryColor font-bold text-lg leading-7">
              Bot created Successfully
            </p>
            <img src={bot7} alt="" className="my-4" />
          </div>
        </div>
   </>
  )
}

export default CopyBot