import React from "react";

const WalletAddressGuide = ({ login1, wa1, wa2, wa3, wa4, wa5, wa6 }) => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
      <p className="text-xl font-semibold text-gray-800 mb-4">
        This guide will walk you through the steps to update your Wallet Address. Follow these steps carefully, and refer to the images provided at each step for visual guidance.
      </p>
      
      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="text-md font-binance-plex leading-7">Step 1: Access the Login Page</p>
        <p className="text-md font-binance-plex leading-7">Step 2: Navigate to the Update Wallet Section</p>
        <p className="text-md font-binance-plex leading-7">Step 3: Click on Update Wallet</p>
        <p className="text-md font-binance-plex leading-7">
          Step 4: Select the Preferred Network and Enter Your Wallet Address
        </p>
        <p className="text-md font-binance-plex leading-7">Step 5: Submit Your Wallet Address</p>
        <p className="text-md font-binance-plex leading-7">Step 6: Verify with OTP</p>
      </div>

      <hr className="border-2 border-gray-400 my-4" />

      <p className="text-lg leading-6 mt-2 font-bold text-SecondaryColor">
        1. Access the Login Page
      </p>
      <img src={login1} alt="Login Page" className="my-4 " />

      <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
        2. Navigate to the Update Wallet Section
      </p>
      <p className="text-md font-binance-plex leading-7">
        Go to the User Dashboard ➔ Profile ➔ Update Wallet.
      </p>
      <img src={wa1} alt="Update Wallet Section" className="my-4 " />

      <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
        3. Click on Update Wallet
      </p>
      <p className="text-md font-binance-plex leading-7">
        Click the <span className="text-colorAccent">"Update Wallet"</span> button to proceed.
      </p>
      <img src={wa2} alt="Click Update Wallet" className="my-4 " />

      <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
        4. Select the Preferred Network and Enter Your Wallet Address
      </p>
      <p className="text-md font-binance-plex leading-7">
        Choose either <span className="text-SecondaryColor">BEP-20</span> or <span className="text-SecondaryColor">TRC-20</span>, and paste your new wallet address.
      </p>
      <img src={wa3} alt="Select Network" className="my-4 " />

      <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
        5. Submit Your Wallet Address
      </p>
      <p className="text-md font-binance-plex leading-7">
        Click the <span className="text-colorAccent">"Update"</span> button to submit your wallet address.
      </p>
      <img src={wa4} alt="Submit Wallet Address" className="my-4 " />

      <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
        6. Verify with OTP
      </p>
      <p className="text-md font-binance-plex leading-7">
        Retrieve the OTP from your email, enter it, and click <span className="text-colorAccent">"Verify & Submit"</span>.
      </p>
      <img src={wa5} alt="Verify with OTP" className="my-4 " />

      <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
        Success!
      </p>
      <p className="text-md font-binance-plex leading-7">
        Your wallet address has been successfully updated.
      </p>
      <img src={wa6} alt="Success Message" className="my-4 " />
    </div>
  );
};

export default WalletAddressGuide;
