import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { RiCheckboxCircleLine } from 'react-icons/ri'


const TransactionPasswordGuide = ({login1,tp1,tp2,tp3,tp4,tp5,tp6,tp7,tp8,tp9,tp10,tp11}) => {
  return (
  <>
  
  <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Create Transaction
            Password. Follow these steps carefully, and refer to the images
            provided at each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold">How to Create a Transaction Password </p>

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md leading-7 font-binance-plex">
              Step 2:Navigate to the Transaction Password Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Create a Transaction Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Set a Strong Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Submit Your Password
            </p>
          </div>

          <hr class="border-2 border-gray-400 my-4" />

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              1. Open the Login Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Go to the Login Page from at{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">
                  app.odecent.com
                </span>
                <FaExternalLinkAlt />
              </a>{" "}
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              2. Navigate to the Transaction Password Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User Dashboard ➔ Profile ➔ Transaction
              Password. This will take you to the section where you can create
              or manage your transaction password
            </p>
            <img src={tp1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              3.Create a Transaction Password:
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click on the "Create Password" button to begin setting up your
              transaction password.
            </p>
            <img src={tp2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              4. Set a Strong Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Create a password that meets the following criteria:
              <br />
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine
                  size={20}
                  className="text-green-500"
                />{" "}
                Minimum of 6 characters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains lowercase letters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains uppercase letters{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains digits.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains special characters.{" "}
              </div>
              As you input your password, the system will indicate the strength
              of your password. Ensure that it meets all the required conditions
              to make it strong.
            </p>
            <img src={tp3} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              5. Submit Your Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once your password is strong and meets all the criteria, click the
              "Submit" button
            </p>
            <img src={tp4} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              {" "}
              Success
            </p>
            <p className="text-md font-binance-plex leading-7">
              Congratulations! Your transaction password has been successfully
              created
            </p>
            <img src={tp5} alt="" className="my-4" />
          </div>

          <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
            <p className="text-xl font-semibold text-gray-800 mb-4">
              This guide will walk you through the steps to Reset Transaction
              Password. Follow these steps carefully, and refer to the images
              provided at each step for visual guidance.
            </p>

            <div className="bg-white p-4 rounded-lg shadow-md mb-4">
              <p className="font-bold">How to Reset a Transaction Password </p>

              <p className="text-md leading-7 font-binance-plex">
                Step 1: Navigate to the Transaction Password Section
              </p>
              <p className="text-md leading-7 font-binance-plex">
                Step 2: Click on Reset Password
              </p>
              <p className="text-md font-binance-plex leading-7">
                Step 3: Create a New Strong Password
              </p>
              <p className="text-md font-binance-plex leading-7">
                Step 4: Request and Enter OTP
              </p>
              <p className="text-md font-binance-plex leading-7">
                Step 5: Submit and Confirm
              </p>
            </div>
            <hr class="border-2 border-gray-400 my-4" />

            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              1. Navigate to the Transaction Password Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Go to User Dashboard ➔ Profile ➔ Transaction Password to manage
              your password settings
            </p>
            <img src={tp6} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              2. Click on Reset Password
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click the "Reset Password" button to start the password reset
              process.
            </p>
            <img src={tp7} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              3.Create a New Strong Password:
            </p>
            <p className="text-md font-binance-plex leading-7">
              Enter a new password that meets the following criteria:
              <br />
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine
                  size={20}
                  className="text-green-500"
                />{" "}
                Minimum of 6 characters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains lowercase letters.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains uppercase letters{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains digits.{" "}
              </div>
              <div className="font-bold flex items-center ">
                {" "}
                <RiCheckboxCircleLine size={20} className="text-green-500" />
                Contains special characters.{" "}
              </div>
              Ensure the password is strong according to the system’s
              indicators.
            </p>
            <img src={tp8} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              4. Request and Enter OTP
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click the "Send OTP" button to receive a One-Time Password (OTP)
              via your registered email. Check your email, retrieve the OTP, and
              enter it in the designated field for security authentication
            </p>
            <img src={tp9} alt="" className="my-4" />
            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              5. Submit and Confirm
            </p>
            <p className="text-md font-binance-plex leading-7">
              After entering the OTP, click the "Submit" button to finalize the
              reset of your transaction password
            </p>
            <img src={tp10} alt="" className="my-4" />

            <p className="font-bold text-SecondaryColor  text-lg leading-6 mt-2">
              Important Note:
            </p>
            <p className="text-md font-binance-plex leading-7">
              You can only reset your transaction password three times. Ensure
              you remember your new password to avoid reaching this limit.
            </p>
            <img src={tp11} alt="" className="my-4" />
          </div>
        </div>,
  
  </>
  )
}

export default TransactionPasswordGuide