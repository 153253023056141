

import React from 'react';
import stepImage from "../Image/Website_images/Easy.webp";
import easyImage from "../Image/Website_images/Steps.webp";


function EasyStep() {

//   useEffect(() => {
//     Aos.init({ duration: 2000 });
// }, []);

  return (
    <div className=" py-10 lg:my-20">
      <div className="container mx-auto p-10 rounded-lg grid grid-cols-1 md:grid-cols-2  ">
       
            <div>
            <h2 className="text-lg mb-5  md:text-3xl  font-bold text-center md:text-left">
            Get Started in <span className="text-SecondaryColor underline">3 Easy </span>
            Steps</h2>
            <img  src={stepImage} alt="Steps Background" className=" inset-0 md:w-2/3  " />
            </div>
         
        <div className="flex justify-center ">
          <img src={easyImage} alt="Easy Steps" className="w-full md:w-[500px] " />
        </div>
        </div>
      </div>
  );
}

export default EasyStep;

