import React, { useEffect } from 'react'
import stepImage from "../Image/Website_images/BG5.webp";
import Aos from 'aos';

function Journey() {

    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, []);
    

    return (
        <>
           
            <div className=" grid grid-cols-1 my-10 xl:my-0 lg:grid-cols-2 items-center w-full  ">

                <div  className="container mx-auto w-full block md:flex justify-center  ">
                    <div className=" text-justify text-black rounded-lg">
                        <h1  className=' text-xl md:text-3xl font-bold mb-7'>
                            UNLOCK YOUR <span className='text-SecondaryColor underline'>SUCCESS <br />JOURNEY</span>
                        </h1>
                        <p  className='mb-4 text-xs md:text-sm'>
                            He has conferred the designation of Chartered Market Technician on 13 December 2004 <br />Member of the CMT(Chartered Market Technician) Association.
                        </p>
                        <p  className='mb-4 text-xs md:text-sm'>
                            Odecent Achievers comes with a myriad of exciting benefits, combining Designation<br /> recognition, Designation advancement, and exclusive rewards within the team.
                        </p>
                        <h2  className=' md:text-xl  mb-2 text-SecondaryColor font-bold'>STAND OUT WITH A BADGE</h2>
                        <p  className='mb-4 text-xs md:text-sm'>Climb the Odecent Achievers ranks, earning a special badge that showcases<br /> your leadership.</p>
                        <h2  className='md:text-xl text-SecondaryColor font-bold mb-2'>EXCLUSIVE REWARDS TIERS</h2>
                        <p  className='mb-4 text-xs md:text-sm'>Reach new rewards, unlock tier! Exclusive gifts, each achievement comes with <br />exciting benefits.</p>
                        <h2  className='md:text-xl text-SecondaryColor font-bold mb-2'>PRIORITY ACCESS TO PERKS</h2>
                        <p  className='mb-4 text-xs md:text-sm'>Enjoy priority access to new products, special promotions, and exclusive<br /> events.</p>
                        <button className='bg-gradient-to-r from-SecondaryColor to-gradientColor text-white px-8 py-2 rounded-md font-bold mt-4'><a href="https://app.odecent.com">Join Now</a></button>
                    </div>
                </div>
                <div className='hidden lg:block'
                // className="hidden lg:block  inset-0 bg-right bg-no-repeat  rounded-2xl"
                // style={{ backgroundImage: `url(${stepImage})` }}
                > <img src={stepImage} />
                </div>
            </div>
        </>
    )
}

export default Journey
