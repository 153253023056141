import React from 'react'

const LiveTradeGuide = ({livetrades1,livetrades2,livetrades3,livetrades4,livetrades5,livetrades6,livetrades7,livetrades8,livetrades9,livetrades10,livetrades11,livetrades12,livetrades13}) => {
  return (
    <>
    {/* // SPOT  */}
     <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Live Spot Trades. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">

          <p className='font-bold'>SPOT Exchange Type</p>
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the <span className='text-SecondaryColor font-bold'>Dashboard </span> and Find the <span className='text-SecondaryColor font-bold'>Trades</span> Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Access the <span className='text-SecondaryColor font-bold'>Spot </span> Section
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 3: Select the <span className='text-SecondaryColor font-bold'>API </span>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Details of Your <span className='text-SecondaryColor font-bold'> Live Spot Balance </span>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Access the <span className='text-SecondaryColor font-bold'> Trade History </span>
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 6: Details of <span className='text-SecondaryColor font-bold'>Live Spot Trade History</span>
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              look for the "Trades" section.
            </p>
            <img src={livetrades1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Access the Live Type and Spot in Trades
            </p>
            <img src={livetrades2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 3. Select the API of Live Spot
            </p>
            <img src={livetrades3} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-md font-binance-plex leading-7">
    <span className="text-SecondaryColor font-bold text-lg">
      Step 4. This will lead you to view your Live Spot Balance,
      which includes the following information:
    </span>
    <ul className="list-disc ml-14 mt-2">
      <li>
        <span className="font-semibold text-SecondaryColor">Assets:</span> The different cryptocurrencies or financial assets held in your spot wallet.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Quantity:</span> The amount of each asset available in your spot wallet.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Price:</span> The current market price of each asset.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Value:</span> The total value of each asset, calculated as Quantity × Price.
      </li>
    </ul>
  </p>
  <img src={livetrades4} alt="Live Spot Balance Overview" className="my-4" />
</div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 5. Access the Live Type and Trade History in Trades
            </p>
            <img src={livetrades5} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-md font-binance-plex leading-7">
    <span className="text-SecondaryColor font-bold text-lg">
      Step 6. This will lead you to view your Live Spot Trade History details,
      which include the following information:
    </span>
    <ul className="list-disc ml-14 mt-2">
      <li>
        <span className="font-semibold text-SecondaryColor">TransTime:</span> The date and time when the trade or transaction took place.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">OrderID:</span> A unique identifier assigned to each specific order or trade.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Symbol:</span> The symbol or ticker representing a specific financial asset or instrument.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Type:</span> The nature of the order, such as market order, limit order, or stop order.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Side:</span> Indicates the direction of the trade, such as "buy" or "sell."
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Price:</span> The price at which the trade was executed.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">O Qty:</span> The original quantity specified when placing the order.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">E Qty:</span> The executed quantity, representing the portion of the order that has been filled.
      </li>
      <li>
        <span className="font-semibold text-SecondaryColor">Status:</span> The current state of the order, such as "open," "filled," or "canceled."
      </li>
    </ul>
  </p>
  <img src={livetrades6} alt="Live Spot Trade History Overview" className="my-4" />
</div>


        </div>

        {/* // FUTURE  */}
        <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Live Future  Trades. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">

          <p className='font-bold'>FUTURE Exchange Type</p>
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the <span className='text-SecondaryColor font-bold'>Dashboard</span>  and Find the <span className='text-SecondaryColor font-bold'>Trades</span> Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Access the <span className='text-SecondaryColor font-bold'> Futures </span> Section
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 3: Select the <span className='text-SecondaryColor font-bold'>API </span>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Click on View all to view Details of Your <span className='text-SecondaryColor font-bold'> Live Future Balance </span>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Access the <span className='text-SecondaryColor font-bold'> Open Positions </span>
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 6: Details of <span className='text-SecondaryColor font-bold'> Live Future Open Position </span>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 7: Access the <span className='text-SecondaryColor font-bold'> Trade History </span>
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 8: Details of <span className='text-SecondaryColor font-bold'> Live Future Trade History </span>
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              look for the "Trades" section.
            </p>
            <img src={livetrades1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Access the Live Type and Futures Exchange Type in Trades
            </p>
            <img src={livetrades7} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 3. Select the API of Live Futures
            </p>
            <img src={livetrades8} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-SecondaryColor font-bold text-lg leading-7">
    Step 4. Click on View all to view all the Details of Live Futures Balance
  </p>
  <ul className="list-disc ml-14 mt-2">
    <li>
      <span className="font-semibold text-SecondaryColor">Margin Balance:</span> The total margin amount available in the account, which can be used to maintain open positions.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Wallet Balance:</span> The overall balance in the futures wallet, including both realized profits and losses.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Unrealized PnL:</span> The potential profit or loss from open positions that has not yet been realized.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Multi-Asset Info:</span> Information on the multiple assets that are being used in the account, applicable when multi-asset mode is enabled.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Available Balance:</span> The balance that is available for opening new positions after considering the margin used by existing positions.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Total Maintenance Margin:</span> The minimum amount of margin required to keep current positions open without facing liquidation.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Total Initial Margin:</span> The margin required to open all current positions.
    </li>
  </ul>
  <img src={livetrades9} alt="Live Futures Balance Overview" className="my-4" />
</div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 5. Access the Open Positions
            </p>
            <img src={livetrades10} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-SecondaryColor font-bold text-lg leading-7">
    Step 6. Details of Live Future Open Position
  </p>
  <ul className="list-disc ml-14 mt-2">
    <li>
      <span className="font-semibold text-SecondaryColor">Symbol:</span> The trading pair or asset symbol representing the specific financial instrument, such as BTC/USDT.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Entry Price:</span> The price at which the position was opened.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Mark Price:</span> The price used to calculate the unrealized PnL and to trigger liquidation. It provides a fairer price.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Liq Price:</span> The liquidation price, which is the price at which the position will be forcibly closed to prevent further losses.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">PS (Position Side):</span> Indicates whether the position is "long" or "short."
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">PA (Position Amount):</span> The amount or size of the open position in terms of the asset.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">PQ (Position Quantity):</span> The quantity or number of contracts in the position.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Unrealized PnL:</span> The potential profit or loss of the open position based on the difference between the mark price and the entry price.
    </li>
  </ul>
  <img src={livetrades11} alt="Live Futures Open Position Overview" className="my-4" />
</div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 7. Access the Trade History
            </p>
            <img src={livetrades12} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-SecondaryColor font-bold text-lg leading-7">
    Step 8. Details of Live Future Trade History
  </p>
  <ul className="list-disc ml-14 mt-2">
    <li>
      <span className="font-semibold text-SecondaryColor">OrderID:</span> A unique identifier for a specific order or trade.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">DateTime:</span> The date and time when the transaction occurred.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Symbol:</span> The trading pair or symbol representing the financial asset, such as BTC/USDT.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Type:</span> The type of order, such as market order, limit order, or stop order.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Side:</span> Indicates whether the trade was a "buy" or "sell" order.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Average:</span> The average price at which the order was executed.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Executed Qty:</span> The quantity that was executed as part of the trade.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Orig Qty:</span> The original quantity specified when the order was placed.
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Position Side:</span> Indicates whether the position is "long" or "short."
    </li>
    <li>
      <span className="font-semibold text-SecondaryColor">Status:</span> The current status of the order, such as "filled," "partially filled," or "canceled."
    </li>
  </ul>
  <img src={livetrades13} alt="Live Future Trade History Overview" className="my-4" />
</div>

         
        </div>
    
    </>
  )
}

export default LiveTradeGuide