import React from 'react'

const ActiveBotsGuide = ({active1,active2,active3}) => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Active Bots. Follow
            these steps carefully, and refer to the images provided at each step
            for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the Dashboard and Find the 'Bot' Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: View Active Bots
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Details of Your Active Bots
            </p>
          </div>

          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              locate the "Bot" section.
            </p>
            <img src={active1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Click on the "ACTIVE BOT" button.
            </p>
            <img src={active2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className=" font-bold text-base leading-7">
              <span className="text-SecondaryColor font-bold text-lg">
                {" "}
                Step 3. This will lead you to view your active bots details,
                which include the following information:
              </span>

              <ul className="list-disc ml-14">
                <li>
                  Date and Time: The date and time when a particular transaction
                  occurred.
                </li>
                <li> Bot ID: A unique identifier for a trading bot.</li>
                <li>
                  Bot Name: The name of the application programming interface
                  (API) used for communication or data exchange.
                </li>
                <li>
                  API Name: The name of the application programming interface
                  (API) used for communication or data exchange.
                </li>
                <li>
                  Exchange: The name of the trading platform or marketplace
                  where trading activities occur.
                </li>
                <li>
                  Exchange Type: The type of trading exchange, such as
                  cryptocurrency exchange.
                </li>
                <li>
                  Trade Type: The specific type of trade, such as buy, sell, or
                  hold.
                </li>
                <li>
                  Trading Symbol: The symbol or ticker representing a particular
                  financial asset.
                </li>
                <li>
                  Investment Amount: The money invested in a particular trade or
                  transaction.
                </li>
              
                <li>
                  Status of the Bot: The current status of a Bot, which can
                  be open, closed, pending, or other relevant states.
                </li>
              </ul>
            </p>
            <img src={active3} alt="" className="my-4" />
          </div>
        </div>
    </>
  )
}

export default ActiveBotsGuide