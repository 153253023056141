import React from 'react'

const BuySubscription = ({sub1,sub2,sub3,sub4}) => {
  return (
   <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Buy Subscription.
            Follow these steps carefully, and refer to the images provided at
            each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access Your Account Dashboard.
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 2: Select the Tab&nbsp;
              <span className="text-SecondaryColor font-bold">
                {" "}
                Subscription{" "}
              </span>
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Choose Your Preferred Subscription.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Confirmation Message for Successful Subscription.
            </p>
          </div>
          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7 mt-3">
              <span className="text-SecondaryColor font-bold">
                Step 1: Log in to your Odecent account and go to the Dashboard.
              </span>
            </p>
            <img src={sub1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold">
                Step 2: Navigate to the "Subscription" section and select the
                desired subscription from the list.
              </span>
            </p>
            <img src={sub2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold">
                Step 3: Click "View More" to check the details, then click
                "Subscribe Now".
              </span>
            </p>
            <img src={sub3} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-binance-plex leading-7">
              <span className="text-SecondaryColor font-bold">
                Step 4: Upon successful subscription, a confirmation message
                will appear.
              </span>
            </p>
            <img src={sub4} alt="" className="my-4 w-[900px] h-[400px]" />
          </div>
        </div>
   </>
  )
}

export default BuySubscription