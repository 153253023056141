import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'



const KYCGuide = ({login1,kyc1,kyc2,kyc3,kyc4,kyc5,kyc6,kyc7}) => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to KYC. Follow these
            steps carefully, and refer to the images provided at each step for
            visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Ensuring your account is verified through the KYC (Know Your
              Customer) process is crucial for full access to all the features
              on the Odecent platform. Follow this step-by-step guide to
              successfully complete your KYC verification. Each step is
              accompanied by images to guide you visually
            </p>

            <br />

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Navigate to the KYC Section.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Open the KYC Form.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Fill Out Your Personal Details.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Verify Your Document with Selfie.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Verify Your Identity.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 7: Final Review and Submission.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 8: Check KYC Status.
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              Step 1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md font-binance-plex leading-7 ">
              Begin by navigating to the Odecent login page.
              <br /> Enter your username and password in the designated fields
              and click on the "Login" button to access your account.
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold"
              >
                app.odecent.com
              </a>{" "}
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              2: Navigate to the KYC Section
            </p>
            <p className="text-md flex  font-binance-plex leading-7 text-justify">
              Once logged in, go to User Dashboard ➔ Profile ➔ KYC Status. This
              will take you to the KYC section of your profile.
            </p>
            <img src={kyc1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              3. Open the KYC Form
            </p>

            <p className="text-md font-binance-plex leading-7 text-justify">
              In the KYC Status section, you will see the status of your KYC
              process. If it shows as "INCOMPLETE," click on it to open the KYC
              form.
            </p>
            <img src={kyc2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              4: Fill Out Your Personal Details
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Carefully fill out the form with your personal information. Ensure
              that all details match your official documents to avoid delays in
              the verification process.
              <br />
              <span className="font-bold">KYC Form Guide</span>: How to Fill Out
              Your Personal Details and Verify Your Identity
              <br />
              <span className="font-bold">First Name</span>: Enter your first
              name exactly as it appears on your official documents.
              <br />
              <span className="font-bold">Last Name</span>: Enter your last name
              as per your official documents.
              <br />
              <span className="font-bold">Email Address</span>: Provide a valid
              email address that you regularly check. This will be used for
              communication and verification.
              <br />
              <span className="font-bold">Date of Birth (yyyy-mm-dd)</span>:
              Enter your date of birth in the format YYYY-MM-DD. Ensure this
              matches your official documents.
              <br />
              <span className="font-bold">Gender</span>: Select your gender from
              the available options.
              <br />
              <span className="font-bold">Country</span>: This field is
              pre-filled as per your registered country.
              <br />
              <span className="font-bold">City of Residence</span>: Enter the
              city where you currently reside.
              <br />
              <span className="font-bold">Address Line 1</span>: Provide the
              first line of your address (e.g., house number, street name).
              <br />
              <span className="font-bold">Address Line 2 (Optional)</span>: You
              can provide additional address details here, such as apartment
              number or building name.
              <br />
              <span className="font-bold">Zip Code</span>: Enter the postal code
              for your address.
              <br />
              <span className="font-bold">Phone Number</span>: This field is
              pre-filled with the country code "+86." Enter your phone number
              without the country code.
            </p>
            <img src={kyc3} alt="" className="my-4" />
            <p>
              Important: Ensure that all information is accurate and matches
              your official documents. Once submitted, these details cannot be
              edited
            </p>
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
  <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
    5: Verify Your Document with Selfie
  </p>
  <p className="text-md font-binance-plex leading-7 text-justify">
    To complete your identity verification, you need to upload a valid identification document along with a selfie.
    <br />
    <span className="font-bold">Identity Verification Instructions</span>
    <br />
   
    <span className="font-bold">Selfie Upload</span>: Take a clear selfie while holding the selected document next to your face.
    <br />
    <span className="font-bold">Document Format</span>: The document and selfie must be in image format (jpg or jpeg).
    <br />
    <span className="font-bold">File Size</span>: Ensure each file is below 2MB.
    <br />
    <span className="font-bold">Criteria for Document Upload</span>:
    <br />
    <span className="font-bold">Expiration</span>: The document must be valid and not expired.
    <br />
    <span className="font-bold">Condition</span>: The document should be in good condition, with all details clearly visible.
    <br />
    <span className="font-bold">Clarity</span>: Avoid any light glare or shadows on the document when taking the photo.
    <br />
    <span className="font-bold">Upload Instructions</span>: Upload a clear image of the front side of your document along with a selfie for ID verification.
  </p>
  <img src={kyc4} alt="" className="my-4" />
</div>





          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              6: Verify Your Identity
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Next, you need to upload a valid identification document to verify
              your identity.
              <br />
              <span className="font-bold">
                Identity Verification Instructions
              </span>
              <br />
              <span className="font-bold">Choose One Document to Upload </span>:
              You can upload a passport, national ID card, or driver's license.
              <br />
              <span className="font-bold">Document Format </span>: The document
              must be in image format (jpg or jpeg).
              <br />
              <span className="font-bold">File Size </span>: Ensure the file
              size is below 2MB.
              <br />
              <span className="font-bold">Criteria for Document Upload</span>:
              <span className="font-bold">Expiration </span>: The document must
              be valid and not expired.
              <br />
              <span className="font-bold">Condition </span>: The document should
              be in good condition, with all details clearly visible.
              <br />
              <span className="font-bold">Clarity </span>: Avoid any light glare
              or shadows on the document when taking the photo.
              <br />
              <span className="font-bold">Upload Instructions </span>: Upload a
              clear image of the front side of your passport or selected
              document for ID verification.
            </p>
            <img src={kyc5} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              7: Final Review and Submission
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Before submitting the form, review all the information and
              documents carefully. This step is crucial to ensure there are no
              errors that could delay the verification process. Once you are
              satisfied with everything, submit the form.
            </p>
            <img src={kyc6} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              8: Check KYC Status
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              After submission, the KYC verification process will begin. You can
              check the status of your KYC under User Dashboard ➔ Profile ➔ KYC
              Status. You will also receive email notifications about the
              progress of your verification.
            </p>
            <img src={kyc7} alt="" className="my-4" />
          </div>
        </div>
    
    </>
  )
}

export default KYCGuide