import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'


const P2PGuide = ({login1,p2p1,p2p2,p2p3,p2p4,p2p5,p2p6}) => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Person to Person.
            Follow these steps carefully, and refer to the images provided at
            each step for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Transferring funds to a team member’s deposit account is simple
              and can help support your team's growth. Follow this step-by-step
              guide to easily transfer funds to a team member’s deposit wallet.
              Visual aids are included at each step for your convenience.
            </p>

            <br />

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Navigate to the Transfer to User Section.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Search for the Team Member.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Enter the Transfer Amount.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Confirm the Transfer.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Enter Your Transaction Password.
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              Step 1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md font-binance-plex leading-7 ">
              Begin by navigating to the Odecent login page.
              <br /> Enter your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                username{" "}
              </span>{" "}
              &{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                password{" "}
              </span>{" "}
              in the designated fields and click on the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent uppercase font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              button to access your account.
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              2: Navigate to the Transfer to User Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User{" "}
              <span className="text-SecondaryColor text-lg font-bold uppercase">
                {" "}
                Dashboard ➔ Wallet ➔ Transfer to User{" "}
              </span>
              . This is where you can initiate a fund transfer to a team member.
            </p>
            <img src={p2p1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              3. Search for the Team Member
            </p>

            <p className="text-md font-binance-plex leading-7 text-justify">
              In the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                Transfer to User section{" "}
              </span>
              , enter the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                username{" "}
              </span>{" "}
              of the team member to whom you want to{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                transfer funds
              </span>
              . Click the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Search
              </span>{" "}
              button to find a valid user. <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                *Note: The user must be a direct team member (user spillover is
                not considered).
              </span>
            </p>
            <img src={p2p2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              4: Enter the Transfer Amount
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Once the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                valid user{" "}
              </span>{" "}
              is found, enter the amount you want to transfer to the selected
              team member’s deposit wallet. Alternatively, you can click the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Max
              </span>{" "}
              button to transfer the total available balance from your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                deposit wallet{" "}
              </span>
            </p>
            <img src={p2p3} alt="" className="my-4" />
            <p>
              Important: Ensure that all information is accurate and matches
              your official documents. Once submitted, these details cannot be
              edited
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              5: Confirm the Transfer
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              After entering the desired amount, click the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Transfer
              </span>{" "}
              button to proceed with the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                fund transfer
              </span>
              .
            </p>
            <img src={p2p4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              6: Enter Your Transaction Password
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              For security purposes, you will be required to enter your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                transaction password{" "}
              </span>{" "}
              to confirm the transfer. Enter your password in the designated
              field and click{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Submit
              </span>{" "}
              to complete the transaction
            </p>
            <img src={p2p5} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              Success!
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Your funds have been successfully transferred to the team member’s{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                deposit wallet
              </span>
              .
            </p>
            <img src={p2p6} alt="" className="my-4" />
          </div>
        </div>
    </>
  )
}

export default P2PGuide