import React from 'react'
import bg_image from '../Image/Website_images/BG6.webp'
import { GrInstagram } from "react-icons/gr";
import { FaFacebook, FaTelegram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { RiTwitterXFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';


function Join() {
  return (
    <>
        <div id='Revolution'>
        <div className="relative ">
                <div
                    className="absolute inset-0 bg-cover hidden lg:block bg-center rounded-2xl"
                    style={{ backgroundImage: `url(${bg_image})`,backgroundSize:"100% 130%" }}
                ></div>
                <div className="relative grid grid-cols-1 md:grid-cols-2  items-center  h-full text-white py-10">
                    <div></div>
                    
                    <div className="text-justify text-black  p-8 rounded-lg">
                        <h1 className='text-xl lg:text-4xl font-bold my-4'>READY TO JOIN THE <span className='text-SecondaryColor'>ALGO <br/>TRADING REVOLUTION?</span></h1>
                        <p className='mb-6 text-sm'>
                        Start your trial today and explore the possibilities Join our vibrant platform for traders and<br/> explore the best Take your trading to the next level with Odecent
                        </p>
                        
                        <button className='bg-gradient-to-r from-SecondaryColor to-gradientColor text-white px-8 py-2 rounded-md font-bold mt-4'><a href="https://app.odecent.com">Join Now</a></button>
                    </div>
                </div>

                <div className='container mx-auto'>
                    <div className='grid grid-cols-1 md:flex items-center justify-center my-5 gap-7'>
                        <h1 className='text-font-semibold text-3xl'>FOLLOW US</h1>
                        <div className='flex z-40 gap-10'>
                        <a href='https://www.instagram.com/odecentofficial/' target='_blank' rel='noopener noreferrer'>
                                <GrInstagram size={35}/>
                            </a>
                            <a href='https://www.facebook.com/odecent' target='_blank' rel='noopener noreferrer'>
                                <FaFacebook size={35}/>
                            </a>
                            <a href='https://t.me/odecentofficial' target='_blank' rel='noopener noreferrer'>
                                <FaTelegram size={35}/>
                            </a>
                            <a href='https://x.com/Odecentofficial' target='_blank' rel='noopener noreferrer'>
                                <RiTwitterXFill size={35}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='bg-SecondaryColor h-7'></div>
        <div className='bg-SecondaryColor h-1 my-2'></div>
    </>
  )
}

export default Join