import React from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'


const WithdrawalGuide = ({login1,w1,w2,w4,w5,w6,w7,w8}) => {
  return (
  <>
  <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to WITHDRAWAL. Follow
            these steps carefully, and refer to the images provided at each step
            for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Withdrawing your funds from Odecent is a straightforward process.
              Follow this step-by-step guide to ensure a smooth withdrawal
              experience. Visual aids are provided for each step to help you
              navigate the process easily.
            </p>

            <br />

            <p className="text-md leading-7 font-binance-plex">
              Step 1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: Navigate to the Withdrawal Section.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Complete Prerequisites.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 4: Check Withdrawal Timing.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 5: Enter the Withdrawal Amount.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 6: Review Withdrawal Details.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 7: Enter Your Transaction Password.
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 8: Check Withdrawal Status.
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg text-SecondaryColor font-bold leading-7 font-binance-plex">
              Step 1: Access the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              Page
            </p>
            <p className="text-md font-binance-plex leading-7 ">
              Begin by navigating to the Odecent login page.
              <br /> Enter your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                username
              </span>{" "}
              &{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                password
              </span>{" "}
              in the designated fields and click on the{" "}
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-colorAccent uppercase font-semiboldbold inline-flex items-center space-x-1"
              >
                <span className="text-SecondaryColor font-bold">Login</span>
                <FaExternalLinkAlt />
              </a>{" "}
              button to access your account.
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              2: Navigate to the Withdrawal Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Once logged in, go to User{" "}
              <span className="text-SecondaryColor text-lg font-bold uppercase">
                {" "}
                Dashboard ➔ Wallet ➔ Withdrawal{" "}
              </span>
              . This section is where you can initiate your{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                withdrawal requests
              </span>
              .
            </p>
            <img src={w1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              3. Complete Prerequisites
            </p>

            <p className="text-md font-binance-plex leading-7 text-justify">
              Before you can proceed with a withdrawal, ensure that the
              following prerequisites are completed:
              <p className="font-bold leading-7 font-binance-plex">
                <a
                  href="/guide#kyc"
                  target="_blank"
                  className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
                >
                  <span className="text-SecondaryColor font-bold">
                    Update KYC
                  </span>
                  <FaExternalLinkAlt />
                </a>{" "}
                : Complete your Know Your Customer (KYC) verification.
              </p>
              <p className="font-bold leading-7 font-binance-plex">
                <a
                  href="/guide#walletaddress"
                  target="_blank"
                  className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
                >
                  <span className="text-SecondaryColor font-bold">
                    Update Wallet Address
                  </span>
                  <FaExternalLinkAlt />
                </a>{" "}
                : Make sure your wallet address is up to date.
              </p>
              <p className="font-bold leading-7 font-binance-plex">
                <a
                  href="/guide#transactionpassword"
                  target="_blank"
                  className="text-colorAccent underline font-semiboldbold inline-flex items-center space-x-1"
                >
                  <span className="text-SecondaryColor font-bold">
                    Set Transaction Password
                  </span>
                  <FaExternalLinkAlt />
                </a>{" "}
                : Ensure your transaction password is set for security purposes.
              </p>
            </p>
            <img src={w2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              4: Check Withdrawal Timing
            </p>
            <p className="text-md  font-binance-plex leading-7 text-justify">
              Withdrawals are only open during a specific{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                24-hour{" "}
              </span>{" "}
              period each week:
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                <br />
                Open: Thursday 11:00 am GMT
                <br />
                Close: Friday 10:59 am GMT{" "}
              </span>
            </p>

            <p className="text-SecondaryColor font-bold">
              Note: The minimum withdrawal amount is $20. There is no maximum
              limit on withdrawals.
            </p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              5: Enter the Withdrawal Amount
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Enter the amount you wish to withdraw. Make sure it meets the
              minimum requirement and then click the{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Submit
              </span>{" "}
              button to proceed.
            </p>
            <img src={w4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              6: Review Withdrawal Details
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              A final{" "}
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                confirmation page{" "}
              </span>{" "}
              will appear, showing the following details:
              <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Available Funds
              </span>
              : The amount available in your withdrawal wallet.
              <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Requested Withdrawal Amount
              </span>
              : The amount you have requested to withdraw.
              <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Admin Fees
              </span>
              : Any applicable administrative fees.
              <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Final Withdrawal Amount
              </span>
              : The net amount that will be sent to your wallet after fees.
              <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                User Wallet Address
              </span>
              : The address where the funds will be sent.
              <br />
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                Review all details carefully to ensure accuracy
              </span>
              .<br />
            </p>
            <img src={w5} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              7: Enter Your Transaction Password
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              To confirm the withdrawal, enter your
              <span className="text-SecondaryColor text-lg font-bold capitalize">
                {" "}
                transaction password{" "}
              </span>{" "}
              in the designated field. This step is crucial for ensuring the
              security of your funds.
            </p>
            <img src={w6} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              8: Check Withdrawal Status
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Once you have submitted your withdrawal request, you can monitor
              its status through your dashboard.
              <br /> Navigate to User{" "}
              <span className="text-SecondaryColor font-bold">
                {" "}
                Dashboard ➔ Transaction ➔ Withdrawal Transaction{" "}
              </span>
              Table.
              <br /> In the last column labeled{" "}
              <span className="text-SecondaryColor font-bold">Status</span> you
              will see the current status of your <br />{" "}
              <span className="text-SecondaryColor font-bold">
                Transaction:
              </span>{" "}
              <span className="text-yellow-600 font-bold">Pending</span>,{" "}
              <span className="text-red-600 font-bold">Failed</span>, or{" "}
              <span className="text-green-600 font-bold">Successful</span>.
            </p>
            <img src={w7} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-6 mt-2">
              Success!
            </p>
            <p className="text-md font-binance-plex leading-7 text-justify">
              Your{" "}
              <span className="text-SecondaryColor text-lg font-bold uppercase">
                {" "}
                withdrawal request{" "}
              </span>{" "}
              has been successfully submitted. Please allow time for processing,
              and keep an eye on the status updates in your dashboard.
            </p>
            <img src={w8} alt="" className="my-4" />
          </div>
        </div>
  
  </>
  )
}

export default WithdrawalGuide