import React from 'react'

function Footer() {
    return (
        <div className='container mx-auto my-4'>
            <div className='block md:flex justify-between items-center gap-5'>
                {/* <div >© Odecent 2024, All Rights Reserved</div> */}
                <div className='text-sm md:text-base'>© Odecent 2024, All Rights Reserved</div>
            </div>
        </div>
    )
}

export default Footer